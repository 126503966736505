import React from 'react'

import { Header as HeaderStyled } from './header.styled'

const Header: React.FC = (props) => {
  return <HeaderStyled id="header" {...props}>
        <h1><a href="/">Nutri Ari Marim</a></h1>
    </HeaderStyled>
}

export default Header
