import axios from 'axios'
import { ICategory, IPost } from '../interfaces'

const base_url = 'https://public-api.wordpress.com/rest/v1.1/sites/nutriarimarim.wordpress.com'

// Posts
export const getPostList = async () => {
    const postList: IPost[] = await axios.get(`${base_url}/posts`).then(response => response.data.posts)

    return postList
}

export const getPostListByCategorySlug = async (categorySlug: string) => {
    const postList: IPost[] = await axios.get(`${base_url}/posts`, {
        params: {
            category: categorySlug
        }
    }).then(response => response.data.posts)

    return postList
}

export const getPostBySlug = async (postSlug: string) => {
    const post: IPost = await axios.get(`${base_url}/posts/slug:${postSlug}`).then(response => response.data)

    return post
}

// Categories
export const getCategoryList = async () => {
    const categoryList: ICategory[] = await axios.get(`${base_url}/categories`).then(response => response.data.categories)

    return categoryList
}

export const getCategoryBySlug = async (categorySlug: string) => {
    const category: ICategory = await axios.get(`${base_url}/categories/slug:${categorySlug}`).then(response => response.data)

    return category
}