import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { scrollToTarget } from "./utils/scroll-to-target";

const HomeView = React.lazy(() => import("./views/home/home.view"));

const LandingView = React.lazy(
  () => import("./views/blog/landing/landing.view")
);
const NotFoundView = React.lazy(
  () => import("./views/not-found/not-found.view")
);
const SingleView = React.lazy(() => import("./views/blog/single/single.view"));
const ServicosView = React.lazy(() => import("./views/servicos/servicos.view"));

const Router: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    scrollToTarget(location.hash);
  }, [location.hash]);

  return (
    <Routes>
      <Route
        index
        path="/"
        element={
          <React.Suspense fallback={<>...</>}>
            <HomeView />
          </React.Suspense>
        }
      />

      <Route
        path="/conteudos"
        element={
          <React.Suspense fallback={<>...</>}>
            <LandingView />
          </React.Suspense>
        }
      />

      <Route
        path="/conteudos/categoria/:categorySlug"
        element={
          <React.Suspense fallback={<>...</>}>
            <LandingView />
          </React.Suspense>
        }
      />

      <Route
        path="/conteudos/pagina/:postSlug"
        element={
          <React.Suspense fallback={<>...</>}>
            <SingleView />
          </React.Suspense>
        }
      />

      <Route
        path="/servicos"
        element={
          <React.Suspense fallback={<>...</>}>
            <ServicosView />
          </React.Suspense>
        }
      />

      <Route
        path="*"
        element={
          <React.Suspense fallback={<>...</>}>
            <NotFoundView />
          </React.Suspense>
        }
      />
    </Routes>
  );
};

export default Router;
