import styled from "styled-components";
import { Container as MuiContainer } from "@mui/material";
import theme from "../../../theme/index.theme";

export const AppVersion = styled.div`
  color: #ccc;
  font-size: 10px;
  text-align: center;
`;

export const Container = styled(MuiContainer)`
  /* Atendimento */
  &.atendimento {
    figure {
      display: block;
      margin: 16px auto 32px;
    }

    img {
      object-fit: contain;
      width: 100%;
    }

    .box-agendamentos {
      padding: 24px;
      color: #ffffff;

      span {
        display: block;
        margin: 8px 0;
      }
    }
  }

  p {
    color: #ffffff;
  }

  /* Contato */
  &.contato {
    background-color: var(--cor-cinza-escuro);
  }

  &.contato p {
    color: #ffffff;
  }

  &.contato .box-contatos {
    padding: 24px;
  }

  &.contato .box-contatos a {
    display: inline-block;
    color: #ffffff;
    margin: 8px 0;
  }

  /* Mapa */
  &.mapa {
    background-color: var(--cor-cinza-escuro);

    p {
      margin: 16px 0;

      a {
        color: #ffffff;
      }
    }

    & iframe {
      border: 0;
    }
  }
`;

export const FooterWrap = styled.footer`
  ${Container} {
    padding: 32px;
  }
`;

export const ContainerFull = styled.div`
  &.primary {
    background-color: ${theme.palette.footer.primary};
  }

  &.secondary {
    background-color: ${theme.palette.footer.secondary};
  }
`;
