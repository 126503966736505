import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { device } from '../../../styles/devices.styled'

export const Navigation = styled.nav`
    background-color: #252d32;
    padding: 16px 32px;
`

export const ToggleButton = styled.div`
    position: absolute;
    top: -45px;
    right: 0;

    svg {
        color: #fff;
        font-size: 28px;
    }

    @media ${device.tablet} {
        top: -78px;
        right: 24px;

        svg {
            font-size: 32px;
        }
    }
`

export const Wrap = styled.div`
    position: fixed;
    top: 45px;
    right: 0;
    z-index: 11;

    &.is-opened {
        width: 100%;
    }

    @media ${device.tablet} { 
        top: 109px;
    }
`

export const NavigationItem = styled(Link)`
    display: block;
    margin: 16px 0;
`

export const NavigationItemText = styled.span`
    color: #fff;
    text-align: right;
    display: block;
`

export const NavigationSubItem = styled(NavigationItem)`
    margin-right: 16px;
`
