import React from 'react'

import GlobalStyle from './styles/global.styled'
import Router from './router'
import { AppWrap } from './App.styled';
import { Main } from './layout';

const App: React.FC = (props) => {
  return <AppWrap id="component-app" {...props}>
    <Main>
      <Router />
    </Main>
    <GlobalStyle />
  </AppWrap>
}

export default App
