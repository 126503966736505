import styled from "styled-components";

export const SectionTitle = styled.h2`
  font-family: var(--font-lato);
  font-weight: 700;
  text-transform: capitalize;
  font-size: 24px;
  color: var(--cor-verde);
  padding-top: 8px;
  margin-bottom: 16px;
`