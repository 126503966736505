import React from "react"

import { Footer, Header, Menu } from "../components"
import { ILayout } from "../interfaces"

export const Main = ({ children }: ILayout) => {
  return <main>
    <Header />
    <Menu />

    {children}

    <Footer />
  </main>
}