import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import theme from "./theme/index.theme";
import { ThemeProvider } from "styled-components";
import ReactGA from "react-ga4";
import { isProduction } from "./utils/is-production";
import { HelmetProvider } from "react-helmet-async";
export const SITE_NAME = "Nutricionista Clínica e Esportiva | Ariane Marim";
export const SITE_URL = "https://www.nutriarimarim.com.br";

if (isProduction()) {
  console.log("GA: true");

  ReactGA.initialize("G-X6NV9V0C0D");
} else {
  console.log("GA: false");
}

const createRootElement = (reactElem: JSX.Element): void => {
  if (document.getElementById("root") == null) {
    const divRoot = document.createElement("div");
    divRoot.setAttribute("id", "root");
    document.body.appendChild(divRoot);
  }

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );

  root.render(
    <React.StrictMode>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>{reactElem}</BrowserRouter>
        </ThemeProvider>
      </HelmetProvider>
    </React.StrictMode>
  );
};

createRootElement(<App />);

export default createRootElement;
