const theme = {
  palette: {
    background: {
      default: "#f8f9fa",
      papper: "#ffffff",
    },
    header: {
      primary: "#252d32",
      textContrast: "#ffffff",
    },
    menu: {
      primary: "#252d32",
      textContrast: "#ffffff",
    },
    footer: {
      primary: "#2B343B",
      secondary: "#252D32",
      textContrast: "#ffffff",
    },
    text: {
      title: "",
      subtitle: "",
      primary: "#008080",
    },
  },
  typography: {
    fontFamily: ["Popins"],
    htmlFontSize: "16px",
    fontSize: "14px",
  },
};

export default theme;
