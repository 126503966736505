import { SectionTitle } from "../../../styles/theme.styled";
import {
  FooterWrap,
  Container,
  ContainerFull,
  AppVersion,
} from "./footer.styled";
import packageJson from "../../../../package.json";

import mapXS from "./map_xs.png";
import mapSM from "./map_sm.png";

export const Footer = (): JSX.Element => {
  return (
    <FooterWrap>
      <ContainerFull className="primary">
        <Container
          id="contato"
          data-title="Contato"
          className="contato"
          maxWidth="lg"
        >
          <SectionTitle className="titulo">Contato</SectionTitle>
          <p>
            Se você está interessado em meus serviços ou gostaria de agendar uma
            consulta nutricional, entre em contato comigo através do telefone ou
            e-mail abaixo. Ficarei feliz em responder a quaisquer perguntas que
            você possa ter!
          </p>

          <div className="box-contatos">
            <ul>
              <li>
                <a href="tel:+5511989731832" target="_blank" rel="noreferrer">
                  <b>Telefone:</b> +55 (11) 98973-1832
                </a>
              </li>
              <li>
                <a
                  href="https://wa.me/5511989731832"
                  target="_blank"
                  rel="noreferrer"
                >
                  <b>WhatsApp:</b> +55 (11) 98973-1832
                </a>
              </li>
              <li>
                <a
                  href="mailto:nutriarimarim@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <b>E-mail:</b> nutriarimarim@gmail.com
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/nutriarimarim/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <b>Instagram:</b> @NutriAriMarim
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/nutriarimarim"
                  target="_blank"
                  rel="noreferrer"
                >
                  <b>Facebook:</b> NutriAriMarim
                </a>
              </li>
            </ul>
          </div>
        </Container>
      </ContainerFull>

      <ContainerFull className="secondary">
        <Container
          maxWidth="lg"
          id="atendimento"
          data-title="Atendimento"
          className="atendimento"
        >
          <SectionTitle className="titulo">Horário de Atendimento</SectionTitle>

          <p>
            Nosso atendimento é realizado exclusivamente com <b>agendamento</b>{" "}
            prévio
          </p>

          <div className="box-agendamentos">
            <ul>
              <li>
                <span>
                  <b>Aberto:</b> de Segunda à Sexta-feira, das 08:00 às 20:00
                </span>
              </li>
              <li>
                <span>
                  <b>Fechado:</b> aos Sábado, Domingos e Feriados
                </span>
              </li>
            </ul>
          </div>
        </Container>
      </ContainerFull>

      <ContainerFull className="primary">
        <Container
          maxWidth="lg"
          id="localizacao"
          data-title="Localização"
          className="localizacao mapa"
        >
          <SectionTitle className="titulo">Endereço</SectionTitle>
          <p>
            Estamos Localizados em frente ao terminal capelinha, na sobreloja:
          </p>

          <p>
            <a href="https://www.google.com/maps/place/Nutricionista+Cl%C3%ADnica+e+Esportiva+%7C+Ariane+Marim,+Sobreloja+em+frente+ao+Terminal+Capelinha+Atendimento+no+Campo+Limpo+e+arredores+Atendimento+na+regi%C3%A3o+da+Aclima%C3%A7%C3%A3o+e+Vl.+Mariana+-+Estr.+de+Itapecerica,+3285+-+Jardim+Germania,+S%C3%A3o+Paulo+-+SP,+05835-005/@-23.6557823,-46.7615466,17z/data=!4m14!1m7!3m6!1s0x94ce53c43b595fab:0x67ca9d7c04fbf8e7!2sEstr.+de+Itapecerica,+3285+-+Jardim+Campo+de+Fora,+S%C3%A3o+Paulo+-+SP,+05835-005!8m2!3d-23.6557823!4d-46.7615466!16s%2Fg%2F11c2fnpzlf!3m5!1s0x94ce59fa5ac7931f:0xf09700ee95d67174!8m2!3d-23.6557823!4d-46.7615466!16s%2Fg%2F11r71d6vwy">
              Estrada de Itapecerica, 3285 - Jardim Germania, São Paulo - SP,
              05835-005
            </a>
          </p>

          <Container sx={{ display: { md: "block", xs: "none" } }}>
            <img alt="map" width="100%" height={"100%"} src={mapXS} />
          </Container>
          <Container sx={{ display: { md: "none", xs: "block" } }}>
            <img alt="map" width="100%" height={"100%"} src={mapSM} />
          </Container>

          <AppVersion>v.{packageJson.version}</AppVersion>
        </Container>
      </ContainerFull>
    </FooterWrap>
  );
};
