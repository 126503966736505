import React from 'react'
import { Banner as BannerStyled } from './banner.styled'

const Banner: React.FC = (props) => {
  return <BannerStyled className="banner-component" {...props}>
        <span className="text-1">Não é uma dieta, é um estilo de vida</span><br />
      <span className="text-2">Escolhas mais <span className="destaque">saudáveis</span>, para uma <span className="destaque">vida</span> mais <span className="destaque">saudável</span></span><br />
        <a href="#contato" className="link">Contate-nos</a>
    </BannerStyled>
}

export default Banner
