import styled from 'styled-components'
import { device } from '../../../styles/devices.styled';

export const Header = styled.header`
  position: fixed;
  background-color: var(--cor-verde);
  width: 100%;
  z-index: 10;
  top: 0;

  h1 {
    text-align: center;
    
  }

  h1>a {
    font-family: "Prata", Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 28px;
    line-height: 45px;
    color: #fff;
  }

  @media ${device.tablet} { 
    padding: 32px 0;
  }
`
