import "@fontsource/lato";
import "@fontsource/prata";

import { createGlobalStyle } from "styled-components";
import { device } from "./devices.styled";

const GlobalStyle = createGlobalStyle`

/* Fonte: Noyh Ligth */
:root {
--cor-verde: #017e86;
--cor-cinza-escuro: #2B343B;
--cor-cinza-claro: #86898c;
--cor-bg-footer-1: #252D32;
--bg-cinza: #f8f9fa;
--borda-cinza: #ebeef0;

--font-lato: "Lato",
Tahoma,
Geneva,
sans-serif;

--font-prata: "Prata",
Arial,
Helvetica,
sans-serif;
}

* {
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box;
margin: 0;
padding: 0;
text-decoration: none;
}

html, body {
  font-size: 16px;
  background-color: var(--bg-cinza);
}

body {
  font-family: "Lato", Tahoma, Geneva, sans-serif;
-webkit-font-smoothing: antialiased !important;
font-weight: 400;
font-size: 16px;
line-height: 26px;
color: #86898c;
background-position: 0% 0%;
background-size: auto;
background-repeat: repeat;
background-attachment: scroll;
counter-reset: section;
margin: 0;
}

p {
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #86898c;
}

body {
  padding-top: 45px;
  
  @media ${device.tablet} { 
	padding-top: 109px;
  }
}
`;

export default GlobalStyle;
