export const scrollToTarget = (target: string): void => {
  setTimeout(() => {
    if (target !== '') {
      const element = document.querySelector(target);
      const header: HTMLElement | null = document.querySelector("#header");

      if (element && header) {
        const yOffset = header.offsetHeight * -1; 
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'}); 
      }

    }
  }, 500)
}
