import React, { useEffect, useState } from "react";
import {
  ToggleButton,
  Navigation,
  NavigationItem,
  NavigationItemText,
  Wrap,
  NavigationSubItem,
} from "./menu.styled";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { blogService } from "../../../services";
import { ICategory } from "../../../interfaces";
import IconButton from "@mui/material/IconButton";
const Menu: React.FC = (props) => {
  const [isOpened, setIsOpened] = useState(false);
  const [categoryList, setCategoryList] = useState<Array<ICategory>>([]);

  const toggleVisibilityMenu = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ): void => {
    setIsOpened(!isOpened);
    e.stopPropagation();
  };

  const getCategoryList = async () => {
    const response = await blogService.getCategoryList();

    setCategoryList([...response]);
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  return (
    <Wrap
      {...props}
      id="menu-component"
      className={isOpened ? "is-opened" : ""}
    >
      <ToggleButton>
        <IconButton aria-label="button" onClick={toggleVisibilityMenu}>
          {isOpened ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      </ToggleButton>

      {isOpened ? (
        <Navigation>
          <NavigationItem
            to={{
              pathname: "/",
              hash: "#sobre",
            }}
            onClick={toggleVisibilityMenu}
          >
            <NavigationItemText>Sobre</NavigationItemText>
          </NavigationItem>

          <NavigationItem to="/servicos" onClick={toggleVisibilityMenu}>
            <NavigationItemText>Serviços</NavigationItemText>
          </NavigationItem>

          <NavigationItem to="/#contato" onClick={toggleVisibilityMenu}>
            <NavigationItemText>Contato</NavigationItemText>
          </NavigationItem>

          <NavigationItem to="/conteudos" onClick={toggleVisibilityMenu}>
            <NavigationItemText>Conteúdos</NavigationItemText>
          </NavigationItem>

          {categoryList.map((category) => {
            if (category.post_count === 0) {
              return null;
            }

            return (
              <NavigationSubItem
                key={category.ID}
                to={`/conteudos/categoria/${category.slug}`}
                onClick={toggleVisibilityMenu}
                state={{ ...category }}
              >
                <NavigationItemText>{category.name}</NavigationItemText>
              </NavigationSubItem>
            );
          })}

          {/*
          <NavigationItem to='/pacientes' onClick={toggleVisibilityMenu}>
              <NavigationItemText>
                  Área do Paciente
              </NavigationItemText>
          </NavigationItem> */}
        </Navigation>
      ) : null}
    </Wrap>
  );
};

export default Menu;
