import styled from "styled-components";
import imgBanner from "./images/mainslider-bg003.webp";

export const Banner = styled.div`
  background-repeat: no-repeat;
  background-image: url(${imgBanner});
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: inherit;
  padding: 24px;
  text-align: center;

  & > br {
    display: none;
  }

  & > .text-1 {
    display: block;
    text-align: center;
    color: var(--cor-verde);
    font-size: 12px;
    font-weight: 700;
  }

  & > .text-2 {
    text-align: center;
    color: #2b343b;
    display: block;
    font-size: 36px;
    font-weight: 400;
    line-height: 44px;
    font-family: "Prata", Arial, Helvetica, sans-serif;
    margin: 8px 0 16px;
  }

  & > .text-2 .destaque {
    color: var(--cor-verde);
  }

  & > .link {
    background-color: var(--cor-verde);
    display: inline-block;
    margin: 0 auto;
    padding: 12px 24px;
    border-radius: 48px;
    font-size: 12px;
    font-weight: 700;
    color: #ffffff;
  }

  & > .link:hover,
  & > .link:active {
    background-color: #2b343b;
  }

  @media only screen and (min-width: 992px) {
    padding: 100px;
    text-align: left;

    & > .text-1 {
      text-align: left;
      font-size: 16px;
      line-height: 24px;
    }

    & > .text-2 {
      text-align: left;
      font-size: 52px;
      line-height: 64px;
      max-width: 640px;
    }
  }
`;
